import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/styles/font.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/PirataOne-Gloomhaven.ttf\",\"weight\":\"400\",\"display\":\"swap\",\"variable\":\"--font-pirata-one\"}],\"variableName\":\"PirataOneGloomhaven\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/styles/font.ts\",\"import\":\"Philosopher\",\"arguments\":[{\"weight\":\"700\",\"display\":\"swap\",\"subsets\":[\"latin\"],\"variable\":\"--font-philosopher\"}],\"variableName\":\"PhilosopherBold\"}");
;
import(/* webpackMode: "eager", webpackExports: ["PWALifeCycle"] */ "/vercel/path0/src/components/@utils/PWALifeCycle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MonsterMirrorStoreProvider"] */ "/vercel/path0/src/services/stores/StoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/services/stores/useStore.ts");
